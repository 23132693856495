body {
  /* background-color: #202020; */
}
.App {
  /* color: #fff; */
  text-align: center;
  width: 100%;
}
.App > * {
  margin-left: auto;
  margin-right: auto;
}
